<section class="section-wallet">
    <div class="inner-tab">
        <button
            class="inner-tab-link"
            (click)="changeTab('personal')"
            *ngIf="personalWallet"
            id="tab1">
            GENERAL USER
        </button>
        <button
            class="inner-tab-link"
            (click)="changeTab('merchant')"
            *ngIf="merchantWallet"
            id="tab2">
            BUSINESS
        </button>
        <button class="inner-tab-link" (click)="changeTab('agent')" *ngIf="agentWallet" id="tab3">
            AGENT
        </button>
    </div>
    <div class="wallet-tab-content" id="tabContent1" *ngIf="personalWallet">
        <div class="row">
            <div class="col-md-6 section-heading">Wallet Balance</div>
        </div>
        <div class="label-value">
            {{ personalWallet.address }}
            <div>
                <a (click)="copyText(personalWallet.address)">
                    <img src="assets/images/copy-icon.png" />copy
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label>FULL:</label>
            </div>
            <div class="col-md-4">
                <label>AVAILABLE:</label>
            </div>
            <div class="col-md-4">
                <label>CASHBACKS:</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <span>{{ personalWallet.fullAmount | number: amountFormat }}</span>
            </div>
            <div class="col-md-4">
                <span>{{ personalWallet.availableAmount | number: amountFormat }}</span>
            </div>
            <div class="col-md-4">
                <span>{{ personalWallet.cashBack | number: amountFormat }}</span>
            </div>
        </div>
    </div>
    <div class="wallet-tab-content" id="tabContent2" *ngIf="merchantWallet">
        <div class="row">
            <div class="col-md-6 section-heading">Wallet Balance</div>
        </div>
        <div class="label-value">
            {{ merchantWallet.address }}
            <div>
                <a (click)="copyText(merchantWallet.address)">
                    <img src="assets/images/copy-icon.png" />copy
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label>FULL:</label>
            </div>
            <div class="col-md-4">
                <label>AVAILABLE:</label>
            </div>
            <div class="col-md-4">
                <label>CASHBACKS:</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <span>{{ merchantWallet.fullAmount | number: amountFormat }}</span>
            </div>
            <div class="col-md-4">
                <span>{{ merchantWallet.availableAmount | number: amountFormat }}</span>
            </div>
            <div class="col-md-4">
                <span>{{ merchantWallet.cashBack | number: amountFormat }}</span>
            </div>
        </div>
    </div>
    <div class="wallet-tab-content" id="tabContent3" *ngIf="agentWallet">
        <div class="row">
            <div class="col-md-6 section-heading">Wallet Balance</div>
        </div>
        <div class="label-value">
            {{ agentWallet.address }}
            <div>
                <a (click)="copyText(agentWallet.address)">
                    <img src="assets/images/copy-icon.png" />copy
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label>FULL:</label>
            </div>
            <div class="col-md-4">
                <label>AVAILABLE:</label>
            </div>
            <div class="col-md-4">
                <label>CASHBACKS:</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <span>{{ agentWallet.fullAmount || '0.00' | number: amountFormat }}</span>
            </div>
            <div class="col-md-4">
                <span>{{ agentWallet.availableAmount || '0.00' | number: amountFormat }}</span>
            </div>
            <div class="col-md-4">
                <span>{{ agentWallet.cashBack | number: amountFormat }}</span>
            </div>
        </div>
    </div>
</section>
