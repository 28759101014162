<div class="wrapper">
    <app-error-model
        message="{{ eMessage }}"
        [display]="display"
        (event)="closeModel($event)"></app-error-model>
    <app-success-model
        message="{{ sMessage }}"
        [display]="displaySuccess"
        (event)="closeModel($event)"></app-success-model>
    <app-navigation *ngIf="loginService.isAdmin()"></app-navigation>
    <app-enterprise-navigation *ngIf="loginService.isEnterprise()"></app-enterprise-navigation>
    <div class="content-wrapper">
        <app-content-transition></app-content-transition>
        <app-loader *ngIf="isLoading" className="custom-loader" text="Processing ..."></app-loader>
        <section class="content-header">
            <div class="row">
                <div class="col-md-6">
                    <div class="tab-heading">
                        <span class="tab-heading-title">Profile</span>
                        <img
                            class="breadcrumb-separator"
                            src="assets/images/breadcrumb-separator.png" />
                        <span class="breadcrumb-custom">Employees</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="right-section">
                        <button class="custombtn" (click)="openForm()">Add New Employee</button>
                    </div>
                </div>
            </div>
        </section>
        <app-enterprise-links [id]="id" [isAdmin]="loginService.isAdmin()"></app-enterprise-links>
        <section class="add-section">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-md-12 section-heading">Employee Search</div>
                </div>
                <div class="row pb-3">
                    <div class="col-md-6">
                        <label class="form-label">SEARCH BY NAME, SURNAME OR EMPLOYEE NUMBER</label>
                        <div class="input-icons">
                            <i class="fa fa-search icon" aria-hidden="true"></i>
                            <input
                                class="form-input"
                                type="text"
                                placeholder="e.g. Name, Surname or Employee number"
                                formControlName="text" />
                            <a *ngIf="form.get('text').value.length > 0" (click)="clearText()">
                                <img src="assets/images/close-x-purple.png" />
                            </a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label"></label>
                        <div>
                            <button class="search-button" (click)="submit()">Search</button>
                            <a class="advanced-search" (click)="toggleOption()">
                                <img src="assets/images/dropdown.png" />
                                <u>ADVANCED SEARCH</u>
                            </a>
                        </div>
                    </div>
                </div>
                <hr *ngIf="showOptions" />
                <div class="search-form" *ngIf="showOptions">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="form-label">STORE / BRANCH / LOCATION</label>
                            <div>
                                <input
                                    class="form-input"
                                    type="text"
                                    placeholder="eg. Claremont"
                                    formControlName="store" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">ACCOUNT STATUS</label>
                            <div>
                                <app-select-box
                                    placeholderValue="Select Status"
                                    [form]="form"
                                    value="status"
                                    [invalidForm]="invalidForm"
                                    [dropDown]="statusDropdown">
                                </app-select-box>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="searched-items">
                    <div *ngIf="searchItems.length > 0">
                        <div *ngFor="let item of searchItems" class="search-item-value">
                            {{ item | humanize }}
                            <a (click)="removeSearchItem(item)">
                                <img src="assets/images/close-x-white.png" />
                            </a>
                        </div>
                    </div>
                </div>
            </form>
        </section>
        <div class="progress-container" *ngIf="isLoading">
            <div class="progress-container-text">
                <div>Searching...</div>
            </div>
            <div>
                <div class="custom-progress-bar">
                    <span [style.width.%]="progressWidth"></span>
                </div>
            </div>
        </div>
        <section *ngIf="search">
            <div class="search-result-header pt-3">
                Search Results: {{ enterpriseCashiers.length }} of {{ pagination.total }}
            </div>
            <hr />
            <div class="container-fluid tab-pane custom-table custom-last-row">
                <br />
                <table id="tableData">
                    <thead>
                        <tr>
                            <th>
                                <app-filter-arrow
                                    filter="name"
                                    title="EMPLOYEE NAME"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow
                                    filter="employeeNumber"
                                    title="EMPLOYEE NO."
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow
                                    filter="mobileNumber"
                                    title="STATUS"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow
                                    filter="role"
                                    title="LOCATION"
                                    (filterValue)="receiveFilter($event)">
                                </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow title="MANAGER"> </app-filter-arrow>
                            </th>
                            <th>
                                <app-filter-arrow title="ACTIONS"> </app-filter-arrow>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="tablebox" *ngFor="let cashier of enterpriseCashiers">
                            <td>
                                <img
                                    class="table-user-img"
                                    src="{{
                                        cashier.profilePicture || 'assets/images/placeholder.png'
                                    }}" />
                                <span>{{ cashier.firstName + ' ' + cashier.lastName }}</span>
                            </td>
                            <td>{{ cashier.employeeNumber }}</td>
                            <td>
                                <app-account-status
                                    employeeStatus="{{ cashier.status }}"
                                    type="user">
                                </app-account-status>
                            </td>
                            <td>{{ cashier.store }}</td>
                            <td>{{ cashier.isManager ? 'Yes' : 'No' }}</td>
                            <td>
                                <app-last-child-enterprise
                                    [user]="cashier"
                                    (event)="update($event)">
                                </app-last-child-enterprise>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot *ngIf="pagination.showPagination()">
                        <tr>
                            <td>
                                Rows per page:
                                <span
                                    ><b>{{ pagination.length }}</b></span
                                >
                                <app-page-length-dropdown (emitLength)="changeLength($event)">
                                </app-page-length-dropdown>
                            </td>
                            <td>
                                <app-page-navigation
                                    pageNumber="{{ pagination.getPageNo() }}"
                                    totalPages="{{ pagination.getTotalPages() }}"
                                    (changePage)="changePage($event)">
                                </app-page-navigation>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </section>
        <div id="employeeModal" class="image-modal" [style.display]="displayForm">
            <div class="add-employee-window">
                <form [formGroup]="employeeForm">
                    <section class="add-section">
                        <div class="row">
                            <div class="col-md-12 section-heading">Add New Employee</div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label">NAME*</label>
                                <div>
                                    <input
                                        class="form-input"
                                        type="text"
                                        formControlName="firstName"
                                        name="firstName"
                                        placeholder="User First Name" />
                                </div>
                                <app-field-error-display
                                    [displayError]="
                                        formUtil.isFieldInValid(employeeForm, 'firstName')
                                    "
                                    errorMsg="first name is required">
                                </app-field-error-display>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label">SURNAME*</label>
                                <div>
                                    <input
                                        class="form-input"
                                        type="text"
                                        formControlName="lastName"
                                        name="lastName"
                                        placeholder="User Last Name" />
                                </div>
                                <app-field-error-display
                                    [displayError]="
                                        formUtil.isFieldInValid(employeeForm, 'lastName')
                                    "
                                    errorMsg="surname is required">
                                </app-field-error-display>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label">SECURITY PIN*</label>
                                <div>
                                    <input
                                        class="form-input"
                                        type="text"
                                        placeholder="e.g. 1234"
                                        name="pin"
                                        formControlName="pin" />
                                </div>
                                <app-field-error-display
                                    [displayError]="formUtil.isFieldInValid(employeeForm, 'pin')"
                                    errorMsg="pin is required">
                                </app-field-error-display>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label">STORE / BRANCH / LOCATION</label>
                                <div>
                                    <input
                                        class="form-input"
                                        type="text"
                                        placeholder="e.g. Claremont"
                                        name="store"
                                        formControlName="store" />
                                </div>
                                <app-field-error-display
                                    [displayError]="formUtil.isFieldInValid(employeeForm, 'store')"
                                    errorMsg="store/branch/location is required">
                                </app-field-error-display>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label">MANAGER (can authorise refunds)</label>
                                <div>
                                    <label class="radio-container">
                                        <input
                                            type="radio"
                                            name="isManager"
                                            value="true"
                                            formControlName="isManager" />
                                        <span class="radio-lable">Yes</span>
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="radio-container">
                                        <input
                                            type="radio"
                                            name="isManager"
                                            value="false"
                                            formControlName="isManager" />
                                        <span class="radio-lable">No</span>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div style="margin-left: 50%">
                                    <a class="deny-window-close" (click)="closeForm(true)"
                                        ><u>CANCEL</u></a
                                    >
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div>
                                    <button
                                        type="submit"
                                        class="box-outline submit-btn"
                                        (click)="submitForm()">
                                        Create Employee
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </form>
            </div>
        </div>
        <div id="employeeUpdateModal" class="image-modal" [style.display]="displayUpdateForm">
            <div class="update-employee-window">
                <form [formGroup]="employeeUpdateForm">
                    <section class="add-section">
                        <div class="row">
                            <div class="col-md-12 section-heading">Employee Details</div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label">NAME*</label>
                                <div class="user-details-text">
                                    {{ employee.firstName || '-' }}
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label">SURNAME*</label>
                                <div class="user-details-text">
                                    {{ employee.lastName || '-' }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label">SECURITY PIN*</label>
                                <div class="user-details-text">
                                    {{ '****' }}
                                    <a
                                        class="download-transaction pl-3"
                                        (click)="openPinUpdateForm()"
                                        ><u>RESET PIN</u></a
                                    >
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label">STORE / BRANCH / LOCATION</label>
                                <div>
                                    <input
                                        class="form-input"
                                        type="text"
                                        placeholder="e.g. store"
                                        name="store"
                                        formControlName="store" />
                                </div>
                                <small
                                    *ngIf="
                                        employeeUpdateForm.get('store').invalid && invalidUpdateForm
                                    "
                                    class="error-text">
                                    This field is required
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label">MANAGER (can authorise refunds)</label>
                                <div>
                                    <label class="radio-container">
                                        <input
                                            type="radio"
                                            name="isManager"
                                            value="true"
                                            formControlName="isManager" />
                                        <span class="radio-lable">Yes</span>
                                        <span class="checkmark"></span>
                                    </label>
                                    <label class="radio-container">
                                        <input
                                            type="radio"
                                            name="isManager"
                                            value="false"
                                            formControlName="isManager" />
                                        <span class="radio-lable">No</span>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div style="margin-left: 50%">
                                    <a class="deny-window-close" (click)="closeUpdateForm()"
                                        ><u>CANCEL</u></a
                                    >
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div>
                                    <button
                                        type="submit"
                                        class="box-outline submit-btn"
                                        (click)="updateEmployee()">
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </form>
            </div>
        </div>
        <div id="employeePinUpdateModal" class="image-modal" [style.display]="displayPinUpdateForm">
            <div class="update-employee-pin-window">
                <form [formGroup]="employeePinUpdateForm">
                    <section class="add-section">
                        <div class="row">
                            <div class="col-md-12 section-heading">Select a new security pin</div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 pb-3 pt-4">
                                <label class="form-label">Choose a 4-digit pin (required)</label>
                                <div>
                                    <input
                                        class="form-input"
                                        type="password"
                                        placeholder="e.g. 1234"
                                        name="store"
                                        formControlName="pin" />
                                </div>
                                <small
                                    *ngIf="
                                        employeePinUpdateForm.get('pin').invalid &&
                                        invalidPinUpdateForm
                                    "
                                    class="error-text">
                                    This field is required
                                </small>
                            </div>
                            <div class="col-md-12 pb-3 pt-2">
                                <label class="form-label">Confirm 4-digit pin (required)</label>
                                <div>
                                    <input
                                        class="form-input"
                                        type="password"
                                        placeholder="e.g. ****"
                                        name="store"
                                        formControlName="confirmPin" />
                                </div>
                                <small
                                    *ngIf="
                                        employeePinUpdateForm.get('confirmPin').invalid &&
                                        invalidPinUpdateForm
                                    "
                                    class="error-text">
                                    This field is required
                                </small>
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col-md-4">
                                <div>
                                    <a class="deny-window-close" (click)="closePinUpdateForm()"
                                        ><u>CANCEL</u></a
                                    >
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div>
                                    <button
                                        type="submit"
                                        class="box-outline submit-btn"
                                        (click)="updateEmployeePin()">
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </form>
            </div>
        </div>
        <div id="verificationModal" class="image-modal" [style.display]="displayPin">
            <div class="verify-employee-window">
                <div class="section-heading pb-4 pl-3 pr-3">
                    {{ authMessage }}
                </div>
                <div class="row pl-5 pr-5">
                    <div class="col-md-12">
                        <div>
                            <input
                                class="form-input"
                                type="password"
                                [(ngModel)]="pin"
                                placeholder="Admin Password" />
                        </div>
                    </div>
                </div>
                <div>
                    <button
                        type="submit"
                        class="box-outline submit-btn mb-2"
                        (click)="authenticate()">
                        Submit
                    </button>
                    <a class="deny-window-close" (click)="closePinModel()"><u>CANCEL</u></a>
                </div>
            </div>
        </div>
    </div>
    <div class="control-sidebar-bg"></div>
</div>
