<tbody>
    <tr class="tablebox" *ngFor="let tran of transactionList; let i = index" [attr.data-index]="i">
        <td>
            <div class="transaction-row">
                <div class="transaction-row-element-1">
                    <div class="transaction-type">
                        {{ tran.type | humanize }}
                        <div
                            class="transaction-group-id"
                            *ngIf="tran.groupId"
                            [style.background-color]="groupIdColours[tran.groupId]">
                            {{ tran.groupId }}
                        </div>
                    </div>
                    <div class="transaction-status" *ngIf="tran.status === status.success">
                        <img class="transaction-status-icon" src="assets/images/green-small.png" />
                        {{ tran.status | uppercase | humanize }}
                    </div>
                    <div class="transaction-status" *ngIf="tran.status === status.pending">
                        <img class="transaction-status-icon" src="assets/images/yellow-small.png" />
                        {{ tran.status | uppercase | humanize }}
                    </div>
                    <div class="transaction-status" *ngIf="tran.status === 'payment pending'">
                        <img class="transaction-status-icon" src="assets/images/yellow-small.png" />
                        {{ tran.status | uppercase | humanize }}
                    </div>
                    <div class="transaction-status" *ngIf="tran.status === status.failed">
                        <img class="transaction-status-icon" src="assets/images/red-small.png" />
                        {{ tran.status | uppercase | humanize }}
                    </div>
                    <div class="transaction-status" *ngIf="tran.status === status.onhold">
                        <img class="transaction-status-icon" src="assets/images/blue-small.png" />
                        {{ tran.status | uppercase | humanize }}
                    </div>
                    <div class="transaction-status" *ngIf="tran.status === status.processing">
                        <img class="transaction-status-icon" src="assets/images/yellow-small.png" />
                        {{ tran.status | uppercase | humanize }}
                    </div>
                    <div class="transaction-status" *ngIf="tran.status === status.cancelled">
                        <img class="transaction-status-icon" src="assets/images/red-small.png" />
                        {{ tran.status | uppercase | humanize }}
                    </div>
                </div>
                <div class="transaction-row-element-2">
                    <div class="transaction-user-name">
                        <a (click)="commonUtil.goToSenderProfile(tran)">
                            <strong
                                ><u>{{ tran.fromUserName }}</u></strong
                            >
                        </a>
                    </div>
                    <img src="assets/images/right-arrow.png" />
                    <div class="transaction-user-name">
                        <a (click)="commonUtil.goToReceiverProfile(tran)">
                            <strong>
                                <u>{{ tran.getToUserName() }}</u>
                            </strong>
                        </a>
                    </div>
                </div>
                <div class="transaction-row-element-3">
                    <div class="transaction-amount" *ngIf="tran.received" style="color: #5bc79c">
                        {{ tran.amount }}
                    </div>
                    <div class="transaction-amount" *ngIf="!tran.received">
                        {{ tran.amount }}
                    </div>
                    <div class="transaction-date">{{ tran.date | date: 'dd/MM/yyyy, h:mm a' }}</div>
                </div>
                <div class="transaction-row-element-4">
                    <img
                        src="assets/images/default-transaction-detail.png"
                        id="{{ 'logo-' + i }}"
                        (click)="showDetails('details-' + i, 'logo-' + i)" />
                </div>
            </div>
            <div class="transaction-details" id="{{ 'details-' + i }}">
                <section class="transaction-details-section">
                    <div class="row mb-2">
                        <div class="col-md-12 header">Overview</div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <label>WHOLESALER</label>
                                    <div class="label-value">
                                        {{ tran.wholesaler | humanize }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>CASHBACKS</label>
                                    <div class="label-value">
                                        {{ tran.getCashbackAmount() }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>
                                        {{ companyService.getSelectedCompanyName() | uppercase }}
                                        TRANS ID
                                    </label>
                                    <div class="label-value">
                                        {{ tran.transactionCode }}
                                        <div>
                                            <a (click)="copyText(tran.transactionCode)">
                                                <img src="assets/images/copy-icon.png" />copy
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6" *ngIf="tran.isPackageCollection()">
                                    <label>PACKAGE COLLECTION STATUS</label>

                                    <div
                                        class="label-value"
                                        *ngIf="tran?.packageCollection?.status === 'Collected'">
                                        <img
                                            class="transaction-status-icon"
                                            src="assets/images/green-small.png" />
                                        {{ tran?.getPackageCollectionStatus() | humanize }}
                                    </div>
                                    <div
                                        class="label-value"
                                        *ngIf="tran?.packageCollection?.status === 'Not Collected'">
                                        <img
                                            class="transaction-status-icon"
                                            src="assets/images/yellow-small.png" />
                                        {{ tran?.getPackageCollectionStatus() | humanize }}
                                    </div>
                                </div>
                                <div class="col-md-6" *ngIf="tran.shopifyOrder?.isCashOnDelivery">
                                    <label>Tele Agent</label>
                                    <div class="label-value">
                                        {{ tran.shopifyOrder?.agent?.name }}
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <label>ORDER REFERRED BY</label>
                                    <div class="label-value">
                                        {{ tran?.shopifyOrder?.referredBy }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>ORDER REFERRED BY CODE</label>
                                    <div class="label-value">
                                        {{ tran?.shopifyOrder?.referralCode }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <label>SENDER FEE</label>
                                    <div class="label-value">
                                        {{ tran.senderFee }}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>RECEIVER FEE</label>
                                    <div class="label-value">
                                        {{ tran.receiverFee }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>BLOCKCHAIN TRANSFER TRANSACTION</label>
                                    @if (tran.transferTransactionId) {
                                        <div class="label-value">
                                            <a
                                                href="{{ tran.transferTransactionId }}"
                                                target="_blank">
                                                <u>VIEW ON BLOCKCHAIN</u>
                                            </a>
                                        </div>
                                    }
                                </div>
                                <!-- @if (tran.isLisk) {
                                    <div class="col-md-6">
                                        <label>BLOCKCHAIN PERMIT TRANSACTION</label>
                                        <div class="label-value">
                                            <a
                                                href="{{ tran.transferTransactionId }}"
                                                target="_blank">
                                                <u>VIEW ON BLOCKCHAIN</u>
                                            </a>
                                        </div>
                                    </div>
                                } -->
                            </div>
                            @if (tran.status === status.failed) {
                                <div class="row">
                                    <div class="col-md-12">
                                        <label>FAILURE MESSAGE</label>
                                        <div class="label-value">
                                            {{ tran.description }}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12 header">Sender Information</div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <label>USER NAME</label>
                                    <div class="label-value">
                                        {{ tran.fromUserName }}
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>MOBILE NUMBER</label>
                                    <div class="label-value">
                                        {{ tran.fromMobileNumber }}
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>ACCOUNT TYPE</label>
                                    <div class="label-value">
                                        {{ tran.fromRole | humanize }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <label>BALANCE BEFORE</label>
                                    <div class="label-value">
                                        {{ tran.fromUserBalanceBefore }}
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>BALANCE AFTER</label>
                                    <div class="label-value">
                                        {{ tran.fromUserBalanceAfter }}
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>USER CODE</label>
                                    <div class="label-value">
                                        {{ tran.fromUserCode }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <label>WALLET ID</label>
                                    <div class="label-value">
                                        {{ tran.fromWalletId || 'NA' }}
                                        <div>
                                            <a (click)="copyText(tran.fromWalletId)">
                                                <img src="assets/images/copy-icon.png" />copy
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>USER REFERRED BY</label>
                                    <div class="label-value">
                                        {{ tran.senderReferredBy }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12 header">Receiver Information</div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <label>USER NAME</label>
                                    <div class="label-value">
                                        {{ tran.toUserName }}
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>MOBILE NUMBER</label>
                                    <div class="label-value">
                                        {{ tran.toMobileNumber }}
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>ACCOUNT TYPE</label>
                                    <div class="label-value">
                                        {{ tran.toRole | humanize }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <label>BALANCE BEFORE</label>
                                    <div class="label-value">
                                        {{ tran.toUserBalanceBefore }}
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>BALANCE AFTER</label>
                                    <div class="label-value">
                                        {{ tran.toUserBalanceAfter }}
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>USER CODE</label>
                                    <div class="label-value">
                                        {{ tran.toUserCode }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <label>WALLET ID</label>
                                    <div class="label-value">
                                        {{ tran.toWalletId || 'NA' }}
                                        <div>
                                            <a (click)="copyText(tran.toWalletId)">
                                                <img src="assets/images/copy-icon.png" />copy
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label>USER REFERRED BY</label>
                                    <div class="label-value">
                                        {{ tran.receiverReferredBy }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </td>
    </tr>
</tbody>
