import { Component, OnInit } from '@angular/core';
import { ICashbackReport } from './../../model/cashback.report';

import { DatePipe } from '@angular/common';
import { Pagination } from 'src/app/model/Pagination';
import { AppConstants } from 'src/app/settings/app-constants';
import { URI } from 'src/app/settings/uri-constants';
import { environment } from 'src/environments/environment';
import { ListView } from './../../model/list.view';
import { CashbackReportService } from './../../services/cashback-report.service';


@Component({
    selector: 'app-cashback-report',
    templateUrl: './cashback-report.component.html',
    styleUrl: './cashback-report.component.css'
})
export class CashbackReportComponent extends ListView implements OnInit {
    amountFormat = AppConstants.AMOUNT_FORMAT;
    search = false;
    wholesalerList: any[];
    supplierList: any[];
    productList: any[];
    cashbackReports: ICashbackReport[] = [];
    cashbackReportList: ICashbackReport[] = [];
    data: any;
    pagination = new Pagination();
    uri = URI;
    publicKey = '';
    balance = '';
    cashbackReportTotal = 0;
    excelDataList = new Array<any>();
    downloadLinkId = 'cashback-reports-link-id';
    CASHBACK_REPORT_START_DATE = '2024-10-01';
    searchItemList = ['wholesalerId', 'supplierId', 'variantIds'];

    // Set the minimum to the date Cashback Report was introduced to the system
    // and set maaximum date to today's date.
    readonly minDate = new Date(this.CASHBACK_REPORT_START_DATE);
    readonly maxDate = new Date();

    constructor(
        private cashbackReportService: CashbackReportService,
        private datePipe: DatePipe) {
        super();
    }

    ngOnInit() {
        this.addSearchControls();
        this.getData();
    }

    // 1. Public Methods for UI Interactions
    submit() {
        this.getData();
    }

    getData() {
        this.isLoading = true;
        this.setPaginationParams();
        this.setDefaultDateRange();
        this.setProgressWidth(50);

        this.fetchCashbackReports();
    }

    getCashbackReportDownloadData() {
        let totalAmount = 0;
        let productPriceGrandTotal = 0;
        this.excelDataList = [];
        const { total } = this.pagination;

        // Update form values for download
        this.searchForm.patchValue({ length: total, start: 0 });

        // Fetch cashback reports and process response
        this.cashbackReportService.getCashbackReports(this.getSearchParams()).subscribe({
            next: (res) => {
                this.data = res.responseBody.data;
                this.cashbackReportList = this.data?.cashbackReports || [];

                // Process each cashback report for Excel export
                this.cashbackReportList.forEach(report => {
                    totalAmount += report.amount;
                    productPriceGrandTotal += report.productPriceTotal;
                    this.excelDataList.push(this.getExcelRowData(report));
                });

                // Add total row to Excel data
                this.excelDataList.push(this.getEmptyExcelRow('', ''));
                this.excelDataList.push(this.getEmptyExcelRow('', ''));
                this.excelDataList.push(this.getEmptyExcelRow(totalAmount, productPriceGrandTotal, 'TOTAL'));

                this.handleClick();
            },
            error: () => {
                this.isLoading = false;
            }
        });
    }

    clearDates() {
        this.searchForm.get('fromDate').setValue('');
        this.searchForm.get('toDate').setValue('');
    }

    setDefaultDateRange() {
        let fromDate = this.searchForm.get('fromDate').value;
        let toDate = this.searchForm.get('toDate').value;

        // Exit early if both dates are empty
        if (!fromDate && !toDate) {
            return;
        }

        if (fromDate) {
            const formattedFromDate = this.datePipe.transform(fromDate, 'yyyy-MM-dd');
            this.searchForm.get('fromDate').setValue(formattedFromDate);
        }

        if (toDate) {
            const formattedToDate = this.datePipe.transform(toDate, 'yyyy-MM-dd');
            this.searchForm.get('toDate').setValue(formattedToDate);
        }
    }

    addSearchControls() {
        // Initialize the newControlsToAdd object with default values
        const defaultControls = {
            address: environment.cashbackReportBlockChainAddress,
            wholesalerId: [],
            supplierId: [],
            variantIds: [],
            fromDate: '',
            toDate: ''
        };

        // Assign default controls to newControlsToAdd
        this.newControlsToAdd = { ...defaultControls };

        // Call the method to add the new controls
        this.addNewControls();
    }

    // 2. Private Helper Methods for Data Handling and API Calls
    private setPaginationParams() {
        this.searchForm.patchValue({
            start: this.pagination.start,
            length: this.pagination.length
        });
    }

    private fetchCashbackReports() {
        this.cashbackReportService.getCashbackReports(this.getSearchParams()).subscribe({
            next: (res) => this.handleCashbackReportSuccess(res),
            error: () => this.handleError(),
            complete: () => this.handleCompletion()
        });
    }

    private handleCashbackReportSuccess(res: any) {
        const { data, total } = res.responseBody;

        if (!data) return; // Early return if no data is available

        const { cashbackReports, publicKey, balance, cashbackReportTotal } = data;

        this.data = data;
        this.cashbackReports = cashbackReports;
        this.publicKey = publicKey;
        this.balance = balance;
        this.cashbackReportTotal = cashbackReportTotal;
        this.pagination.total = total;

        this.assignFilterData();
        this.addSearchItems();
    }

    private handleError() {
        this.isLoading = false;
    }

    private handleCompletion() {
        this.isLoading = false;
        this.showSearchResultRow = !this.isLoading && this.cashbackReports?.length > 0;
    }

    // 3. Private Methods for Data Formatting and UI Updates
    private assignFilterData() {
        const { wholesalers, products, suppliers } = this.data?.filters || {};

        this.wholesalerList = wholesalers;
        this.productList = products;
        this.supplierList = suppliers;
    }

    // 4. Utility Methods for Excel Formatting
    private getExcelRowData(cashbackReport: ICashbackReport) {
        return {
            'Customer': cashbackReport.toUser,
            'Wholesaler': cashbackReport.wholesaler,
            'Supplier': cashbackReport.supplier,
            'Product': cashbackReport.title,
            'Quantity': cashbackReport.quantity,
            'Amount': cashbackReport.amount,
            'Total Product Price': cashbackReport.productPriceTotal,
            'Date': this.datePipe.transform(cashbackReport.createdAt, 'd/MM/yyyy'),
            'Invoice No.': cashbackReport.orderNumber
        };
    }

    private getEmptyExcelRow(totalAmount: any, productPriceGrandTotal: any, totalLabel = '') {
        return {
            'Customer': totalLabel,
            'Wholesaler': '',
            'Supplier': '',
            'Product': '',
            'Quantity': '',
            'Amount': totalAmount,
            'Total Product Price': productPriceGrandTotal,
            'Date': '',
            'Invoice No.': ''
        };
    }

    // 5. Method for Handling Click
    handleClick() {
        document.getElementById(this.downloadLinkId)?.click();
    }

    // 6. Utility Methods for Search Parameters
    getSearchParams() {
        const params = { ...this.searchForm.value };

        params.wholesalerId ??= '';
        params.supplierId ??= '';


        if (params.fromDate) {
            params.fromDate = this.datePipe.transform(params.fromDate, 'yyyy-MM-dd');
        }

        if (params.toDate) {
            params.toDate = this.datePipe.transform(params.toDate, 'yyyy-MM-dd');
        }

        return params;
    }

}
