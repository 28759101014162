export class Wallet {
    public fullAmount: string;
    public availableAmount: string;
    public cashBack: string;
    public publicKey: string;
    public address: string;

    constructor() {
        this.fullAmount = '0.00';
        this.availableAmount = '0.00';
        this.cashBack = '0.00';
        this.publicKey = '';
        this.address = '';
    }
}
