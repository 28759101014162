import { DatePipe, DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { FeeCode } from 'src/app/enums/fee.code';
import { CommonService } from 'src/app/services/common.service';
import { CountryService } from 'src/app/services/country.service';
import { Enterprise } from '../model/enterprise';
import { Fees } from '../model/fees';
import { SessionUser } from '../model/session.user';
import { Transaction } from '../model/transaction';
import { User } from '../model/user';
import { HumanizePipe } from '../pipes/humanize.pipe';
import { StorageConstant } from '../settings/storage-constants';
import { URI } from '../settings/uri-constants';
import { LoginService } from './login.service';

@Injectable({ providedIn: 'root' })
export class CommonUtil {
    // Use FeeCode enum keys as new object key. Use -readonly to allow for update
    feeLabel: { -readonly [key in keyof typeof FeeCode]?: any };
    document: Document = inject(DOCUMENT);

    constructor(
        private router: Router,
        private loginService: LoginService,
        private datePipe: DatePipe,
        private humanizePipe: HumanizePipe,
        private countryService: CountryService,
        private formBuilder: UntypedFormBuilder,
        private commonService: CommonService,
    ) { }

    public getTxnGroupIdColours(transactions: Transaction[]) {
        const uniqueGroupIds = [...new Set(transactions.map(({ groupId }) => groupId))];
        const groupIdsWithColour = uniqueGroupIds.reduce(
            (o, key) => Object.assign(o, { [key]: this.randomColor(200) }),
            {},
        );
        return groupIdsWithColour;
    }

    private randomColor(brightness) {
        function randomChannel(brightness) {
            const r = 255 - brightness;
            const n = 0 | (Math.random() * r + brightness);
            const s = n.toString(16);
            return s.length == 1 ? '0' + s : s;
        }
        return (
            '#' + randomChannel(brightness) + randomChannel(brightness) + randomChannel(brightness)
        );
    }

    public getExcelofTransaction(tran: Transaction) {
        const excel = {
            'TRANSACTION CODE': tran.transactionCode,
            'TRANSACTION TYPE': tran.type,
            AMOUNT: parseFloat((tran.amount || 0).toString()),
            DATE: new Date(this.datePipe.transform(tran.date, 'yyyy-MM-dd')),
            TIME: this.datePipe.transform(tran.date, 'hh:mm:ss'),
            'TRANSACTION STATUS': tran.status,
            'FROM ROLE': tran.fromRole,
            'FROM USER NAME': tran.fromUserName,
            'FROM MOBILE NUMBER': tran.fromMobileNumber,
            'FROM (REFERRED By)': tran.senderReferredBy,
            'FROM SYSTEM ID NUMBER': tran.fromUserCode,
            'FROM BALANCE BEFORE': parseFloat(tran.fromUserBalanceBefore || '0'),
            'FROM BALANCE AFTER': parseFloat(tran.fromUserBalanceAfter || '0'),
            'TO ROLE': tran.toRole,
            'TO USER NAME': tran.getToUserName(),
            'TO MOBILE NUMBER': tran.toMobileNumber,
            'TO (REFERRED By)': tran.receiverReferredBy,
            'TO SYSTEM ID NUMBER': tran.toUserCode,
            'TO BALANCE BEFORE': parseFloat(tran.toUserBalanceBefore || '0'),
            'TO BALANCE AFTER': parseFloat(tran.toUserBalanceAfter || '0'),
            'SETTLEMENT BATCH NO': tran.extras.settlementBeneficiaryRef || '',
            'SAGEPAY KEY': tran.extras.sagepayKey || '',
            'TRANSACTION SENDER FEE': parseFloat((tran.senderFee || 0).toString()),
            'TRANSACTION RECEIVER FEE': parseFloat((tran.receiverFee || 0).toString()),
            'CASHBACK FEE': parseFloat((tran.cashbackFees || 0).toString()),
            'FEES INCURRED': '',
            'CASHBACK REWARD GIVEN': parseFloat((typeof tran.cashback === 'number' ? tran.cashback : 0).toString()),
            'CASHBACK REWARD RECEIVED': parseFloat((typeof tran.cashback === 'number' ? tran.cashback : 0).toString()),
            'AGENT SYSTEM ID': tran.agent.userCode || '',
            'AGENT STORE NAME': tran.agent.storeName || '',
            'AGENT STORE ADDRESS': tran.agent.storeAddress || '',
            'GL ACCOUNT NUMBER': '',
            DESCRIPTION: tran.description,
            'BLOCKCHAIN TRANSFER TXN URL': tran.transferTransactionId
        };

        return excel;
    }

    public getExcelOfUsers(user: User) {
        const excel = {
            'NAME & SURNAME': user.firstName + ' ' + user.lastName,
            'BUSINESS NAME': user.businessName,
            'MOBILE NUMBER': user.mobileNumber,
            'PERSONAL ACCOUNT':
                user.status != null ? this.humanizePipe.transform(user.status) : 'NA',
            'BUSINESS ACCOUNT':
                user.businessStatus != null
                    ? this.humanizePipe.transform(user.businessStatus)
                    : 'NA',
            'AGENT ACCOUNT':
                user.agentStatus != null ? this.humanizePipe.transform(user.agentStatus) : 'NA',
            'DATE CREATED': new Date(this.datePipe.transform(user.dateCreated, 'yyyy-MM-dd')),
            'REFERRED BY': user.referredByName,
            'REFERRED BY CODE': user.referredByCode,
        };

        return excel;
    }

    navigate(type: string, user: any) {
        localStorage.setItem(StorageConstant.USER_PROFILE + user.id, JSON.stringify(user));

        if (user.enterpriseStatus) {
            const sessionUser: SessionUser = JSON.parse(
                localStorage.getItem(StorageConstant.AUTH_USER),
            );
            sessionUser.enterprise = new Enterprise();
            sessionUser.enterprise.enterpriseId = user.enterpriseId;
            sessionUser.enterprise.userId = user.id;
            this.loginService.sessionUser.next(sessionUser);
            localStorage.setItem(StorageConstant.AUTH_USER, JSON.stringify(sessionUser));

            switch (type) {
                case 'profile':
                    this.router.navigate([URI.SLASHED_ENTERPRISE_PROFILE_SUMMARY]);
                    break;

                case 'transaction':
                    this.router.navigate([URI.SLASHED_ENTERPRISE_TRANSACTION_ADMIN]);
                    break;
            }
        } else {
            switch (type) {
                case 'profile':
                    const pathProfile = URI.SLASHED_VIEW_PROFILE_SUMMARY.replace(':id', user.id);
                    this.router.navigate([pathProfile]);
                    break;

                case 'transaction':
                    const pathTransaction = URI.SLASHED_VIEW_USER_TRANSACTIONS.replace(
                        ':id',
                        user.id,
                    );
                    this.router.navigate([pathTransaction]);
                    break;
            }
        }
    }

    private getFromUser(tran: Transaction) {
        const user: User = new User();
        user.id = tran.fromUserId;
        user.enterpriseId = tran.enterpriseId;

        if (user.enterpriseId) {
            user.enterpriseStatus = 'active';
        }

        return user;
    }

    private getToUser(tran: Transaction) {
        const user: User = new User();
        user.id = tran.toUserId;
        user.enterpriseId = tran.enterpriseId;

        if (user.enterpriseId) {
            user.enterpriseStatus = 'active';
        }

        return user;
    }

    public navigateToProfile(type: string, user: any) {
        if (!this.userObjectHasFilterFields(user)) {
            const form = this.formBuilder.group({
                start: [0],
                length: [1],
                userId: [user.userId || user.id],
            });

            this.commonService.getFilteredData(form).subscribe((res) => {
                let userList = new Array<User>();
                let userForNav = user;
                userList = res.data || [];

                if (userList.length > 0) {
                    userForNav = userList[0];
                }

                this.navigate('profile', userForNav);
            });
        } else {
            this.navigate('profile', user);
        }
    }

    // navigate to Sender Profile
    public goToSenderProfile(tran: Transaction) {
        this.navigateToProfile('profile', this.getFromUser(tran));
    }

    // navigate to Receiver Profile
    public goToReceiverProfile(tran: Transaction) {
        this.navigateToProfile('profile', this.getToUser(tran));
    }

    public validateSaIdNumber(saIdNumber: string) {
        const SA_ID_FORMAT =
            /(?<Year>[0-9][0-9])(?<Month>([0][1-9])|([1][0-2]))(?<Day>([0-2][0-9])|([3][0-1]))(?<Gender>[0-9])(?<Series>[0-9]{3})(?<Citizenship>[0-9])(?<Uniform>[0-9])(?<Control>[0-9])/;

        if (!SA_ID_FORMAT.test(saIdNumber)) {
            return false;
        }

        let sum = 0;
        let alternate = false;
        for (let i = saIdNumber.length - 1; i >= 0; i--) {
            let n = parseInt(saIdNumber.substring(i, i + 1));
            if (alternate) {
                n *= 2;
                if (n > 9) {
                    n = (n % 10) + 1;
                }
            }
            sum += n;
            alternate = !alternate;
        }
        return sum % 10 == 0;
    }

    public getFeeLabels() {
        // Error if you use a key that is not available in FeeCode enum
        return (this.feeLabel = {
            [FeeCode.marketplace_pickup_cashback]: 'Pickup Cashback',
            [FeeCode.marketplace_margin]: 'Lovcash Margin',
            [FeeCode.marketplace_delivery]: 'Delivery Fee',
            [FeeCode.sender_cot]: 'Cash Out',
            [FeeCode.sender_cin]: 'Agent Cash In',
            [FeeCode.sender_scz]: 'Send',
            [FeeCode.sender_pay]: 'Make a Payment',
            [FeeCode.sender_ttb]: 'Transfer to Bank (Withdrawal)',
            [FeeCode.sender_age]: 'Transfer',
            [FeeCode.sender_mpb]: 'Market Place Fee',
            [FeeCode.sender_mpb_pkg]: 'Market Place Package Collection',
            [FeeCode.sender_svc]: 'Send Voucher',
            [FeeCode.sender_busdv]: 'Sell USD-V',
            [FeeCode.sender_susdv]: 'Sell USD-V',
            [FeeCode.sender_cin_earning]: 'Cash In Earnings',
            [FeeCode.receiver_cin]: 'Cash In',
            [FeeCode.receiver_cot]: 'Agent Cash Out',
            [FeeCode.receiver_scz]: 'Receive',
            [FeeCode.receiver_pay]: 'Recieve Payment',
            [FeeCode.receiver_cbk]: 'Cashback fee to Lov.Cash',
            [FeeCode.receiver_cdp]: 'Agent Cash Drop',
            [FeeCode.receiver_cin_eft]: 'EFT Cash In',
            [FeeCode.receiver_cin_atm]: 'ATM Cash In',
            [FeeCode.receiver_cin_cod]: 'Cash on Delivery',
            [FeeCode.receiver_cin_store]: 'Cash In Store',
            [FeeCode.receiver_cin_card]: 'Card Cash In',
            [FeeCode.receiver_cin_instant_eft]: 'Instant EFT',
            [FeeCode.receiver_susdv]: 'Buy USD-V',
            [FeeCode.receiver_svc]: 'Receive Voucher (from Lov.Cash)',
            [FeeCode.receiver_busdv]: 'Buy USD-V',
        });
    }

    // get fee object from list using fee code
    public getFee(fees: Array<Fees>, code: any): Fees {
        const feeCode = this.addCountryCodeToFeeCode(code);

        for (const fee of fees) {
            if (fee.code.includes(feeCode) && fee.code.endsWith(feeCode)) {
                return fee;
            }
        }
        return new Fees();
    }

    public showFeeRow(fees: Array<Fees>, code: any) {
        return JSON.stringify(this.getFee(fees, code)) !== JSON.stringify(new Fees());
    }

    public getProvinceId(province: any, name: string): string {
        const val = province.filter((x) => x.province == name)[0];
        let id = '';

        if (val) {
            id = val.id;
        }
        return id;
    }

    // ***************PRIVATE METHODS**************************//

    private addCountryCodeToFeeCode(feeCode: string) {
        if (feeCode !== null) {
            return feeCode + '_' + this.countryService.getCountryCodeByCompany();
        }
        return feeCode;
    }

    // Check if certain properties are present in a user object
    private userObjectHasFilterFields(user: any) {
        return (
            user.hasOwnProperty('businessStatus') &&
            user.hasOwnProperty('companyId') &&
            user.hasOwnProperty('status') &&
            user.hasOwnProperty('accounts')
        );
    }

    copyText(val: string) {
        const input = document.createElement('input');
        document.body.appendChild(input);
        if (val) {
            input.value = val;
        } else {
            input.value = 'NA';
        }
        input.select();
        input.setSelectionRange(0, 99999);

        // Copy the text inside the text field
        navigator.clipboard.writeText(input.value);
        input.remove();
    }

}
