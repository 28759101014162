import { Role } from '../enums/role';
import { Bank } from './bank';
import { Deserializable } from './deserializable';
import { TransactionLog } from './transaction.log';

export class Transaction implements Deserializable {
    public fromUserId: string;
    public toUserId: string;
    public transactionCode: string;
    public type: string;
    public amount: number;
    public fees: number;
    public senderFee: number;
    public receiverFee: number;
    public status: string;
    // public cashback: number;
    public cashbackFees: number;
    public fromWalletId: number;
    public toWalletId: number;
    public date: string;
    public description: string;
    public fromUserName: string;
    public toUserName: string;
    public fromRole: string;
    public toRole: string;
    public fromMobileNumber: string;
    public toMobileNumber: string;
    public employeeNumber: string;
    public transferTransactionId: string;
    public permitTransactionId: string;
    public isLisk = false;
    public profilePicture: string;
    public received = false;
    public canRefund = false;
    public parentId: string;
    public refundTxnCode: string;
    public settlementRequestId: string;
    public receivedAt: string;
    public settleOn: string;
    public senderFeePercentage: number;
    public bankDetails = new Bank();
    public transactionLog = new Array<TransactionLog>();
    public fromUserCode: string;
    public toUserCode: string;
    public enterpriseId: string;
    public fromUserBalanceBefore: string;
    public fromUserBalanceAfter: string;
    public toUserBalanceBefore: string;
    public toUserBalanceAfter: string;
    public senderReferredBy: string;
    public receiverReferredBy: string;
    public processing = true;
    public isShopifyCashIn = false;
    public groupId: string;
    public wholesaler: string;

    public shopifyOrder: {
        isCashOnDelivery: boolean;
        referralCode: string;
        referredBy: string;
        agent: {
            userRoleId: number;
            name: string;
        };
    };

    public agent: {
        userCode: string;
        storeName: string;
        storeAddress: string;
    };

    public extras: {
        settlementBeneficiaryRef: string;
        sagepayKey: string;
    };

    public packageCollection: {
        orderNumber: string;
        status: string;
    };

    public cashback: {
        id: number;
        amount: number;
        status: string;
        source: string;
        transactionCode: string;
        wavesTxId: string;
        pickupCashback: number;
        wavesPickupCashbackTxId: string;
        pickupCashbackPercentage: number;
        isRefund: boolean;
        wavesCashbackFeeTxId: string;
        cashbackFees: number;
        CashbackFeePercentage: number;
        walletId: number;
    };

    constructor() { }

    public getPackageCollectionStatus() {
        return this.packageCollection.status === 'Collected'
            ? 'Package Collected'
            : 'Pending Collection';
    }

    public isPackageCollection() {
        return this.packageCollection !== null && Object.keys(this.packageCollection).length > 0;
    }

    getCashbackAmount() {
        let cashback = 0;

        if (this.cashback) {
            if (this.cashback.transactionCode === this.transactionCode) {
                cashback = this.cashback.amount;
            } else {
                cashback = this.cashback.pickupCashback;
            }
        }
        return cashback;
    }

    getEntTxnFromUserBalanceBefore() {
        return this.fromRole === Role.enterprise ? this.fromUserBalanceBefore : '';
    }

    getEntTxFromUserBalanceAfter() {
        return this.fromRole === Role.enterprise ? this.fromUserBalanceBefore : '';
    }

    getEntTxToUserBalanceBefore() {
        return this.toRole === Role.enterprise ? this.toUserBalanceBefore : '';
    }

    getEntTxToUserBalanceAfter() {
        return this.toRole === Role.enterprise ? this.toUserBalanceAfter : '';
    }

    getToUserName() {
        return this.toRole === Role.enterprise_staff
            ? this.toUserName + ' (' + this.wholesaler + ')'
            : this.toUserName;
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
